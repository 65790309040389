// Here you can add other styles
.card,
.dropdown-menu,
.footer,
.header,
.sidebar {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px;
}

.sidebar-overlaid {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px,
    0 5px 6px -3px rgb(0 0 0 / 25%);
}

.card-header {
  border-bottom: 1px solid var(--cui-card-border-color);
}

.footer,
.header {
  border: 0;
}

.sidebar-brand {
  justify-content: space-between;
  padding: 0 ($sidebar-nav-link-padding-x * 0.5) 0 ($sidebar-nav-link-padding-x * 0.75);
}

.sidebar-toggler {
  flex: 0 0 2rem;
  background-color: transparent;

  &::before {
    width: 2rem;
  }

  &:hover {
    background-color: transparent;
  }
}

.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-brand {
    justify-content: center;
    padding: 0;
  }
  .sidebar-toggler {
    display: none;
  }
}
.ck-editor__editable {
  min-height: 300px;
}

.z-top {
  z-index: 1099;
}

// This is for LEAD Page
.lead-blur-row {
  filter: blur(5px); /* Adjust the pixel value to increase or decrease the blur effect */
}

// This is for PROFILE/PAYMENT
.payment-container {
  background-color: rgba(53, 184, 168, 0.25);
}

.payment-container,
.transaction-details {
  border: 1px solid #ffffff;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
}

.payment-container p,
.amount {
  color: #35b8a8;
}

.amount {
  font-size: calc(1vw + 30px);
  font-weight: bold;
}

.centered-content {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.payment-btn-add {
  background-color: #35b8a8; /* Teal background */
  border-radius: 8px; /* Rounded corners */
  border: none; /* No border */
  color: white; /* Text color */
  padding: 10px 20px; /* Some padding to make the button bigger */
  cursor: pointer; /* Cursor indicates the element is clickable */
  outline: none; /* Removes the outline to make it completely borderless */
}

.payment-btn-add span {
  color: white;
  font-size: calc(1vw + 8px);
}

.payment-container h2,
.transaction-details h2 {
  margin-top: 0;
}

.transaction {
  margin-top: 10px;
}

.transaction.deduction {
  color: red;
}

.transaction.top-up {
  color: green;
}

//This is for profile/payment/topup
.topup-inner-container {
  background-color: skyblue;
  border: 1px solid #000000;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
}

.topup-outer-container {
  background-color: white;
}

.form-check-margin {
  margin-bottom: 10px;
}
